<template>
  <div class="s009">
    
      <div id="form">
      <div class="inner-form">

        <!-- buscador-->
        <div class="basic-search">
          <div class="input-field">
              <input id="search" type="text" ref="keyword" v-model="formDetails.ask" placeholder="Preguntame algo sobre Agility"
              v-on:keyup="keymonitor"
              />
              <div class="icon-wrap" @click="askAgility();" :disabled="isLoading">
                <svg class="svg-inline--fa fa-search fa-w-16" fill="#ccc" aria-hidden="true" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                </svg>
              </div>
            </div>
        </div>

        <!-- /buscador-->
        <!-- bloque canvas-->

        <div class="advance-search">
            <span class="desc" v-if="queryresult">{{ question }}</span>
            <div class="row">
                <div class="text-center" v-if="errorQuery">
                    <span style="font-size:13px;">{{ errorQuery }}</span>
                </div>              
              <div id="content"><span v-html="queryresult"></span></div>
              
             
            </div>
            <div class="row">
                <div id="mark" class="agility-mark animate__animated animate__bounce">
                    <img src="https://www.agilityrpa.com/img/recursos/logo-agility.svg" class="img-fluid" style="width: 200px; margin: 0 auto;" />
                    <div id="loading" v-if="isLoading">Consultando....</div>
                </div>
            </div>

            <!-- related-->
            <div class="related" v-if="related.length > 0">
              <hr />
              <div class="desc">Quizas te podria interesar</div>
              <div class="row justify-content-start">                
                <div class="col-md-auto mt-1" v-for="item in related" :key="item.id">
                  <button  class="btn btn-light  btn-md text-nowrap icon-link" @click="searchRelated(item.id)"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
                    {{ item.title }}</button>
                </div>
              </div>
            </div>
            <!-- /related-->
         
            <div class="row third"  v-if="queryresult">
              <div class="input-field">
                <div class="result-count">
                  <span>{{totalResult}} </span>resultado</div>
                <div class="group-btn">     
                  <button class="btn-search" @click="cleanvariables">Limpiar</button>
                </div>
              </div>
            </div>
          </div>
        <!-- /bloque canvas-->
        <!-- footer -->
        <div class="footer">
            <a href="https://www.agilityrpa.com/" target="_blank" title="Desarrollado por Enterdev"><img src="@/assets/proweredby-end.png"  /></a>
         </div>
        <!-- /footer-->


      </div>
    </div><!-- /form-->
    
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import { marked } from 'marked';

export default {
  name: 'HomeView',
  components: {
   
  },
  data() {
        return {
          elementmark: document.querySelector('#mark'),    
          question:'',
          isLoading:false,
          successMessage: "",
          queryresult: "",
          errorMessage: "",
          errorQuery: "",
          totalResult: 0,
          related: [],
          formDetails: {ask: ''},
          showResult: true,
          configHeader: {
              headers:{
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFxdmNzeGdsa29icXVjeHNqY3dvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODI0NjUyNTMsImV4cCI6MTk5ODA0MTI1M30.Zi12DwFjstLtKj1E-x-ietfPbQ2wQYDAQkB9ANQuVPA',
                
              }
          }
        }
    },//data
    mounted() {
      this.elementmark = document.querySelector('#mark')
      console.log('Component mounted.')
      this.$refs.keyword.focus();
      hljs.highlightAll();
    },
    methods:{
      askAgility: function(){       
  
        if (this.formDetails.ask.trim() == '') {
                this.errorMessage = 'Please enter a question';
                return
        }
        this.cleanvariables()

        let data = JSON.stringify({
                "query": this.formDetails.ask
        })
        this.queryresult = ''
        this.totalResult = 0
        this.isLoading = true

        let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://qqvcsxglkobqucxsjcwo.functions.supabase.co/chatgpt/search',
                //url: 'https://pokeapi.co/api/v2/pokemon/ditto',
                headers: this.configHeader.headers,
                data : data
        }
        this.elementmark.classList.add('animate__zoomIn')
        const app = this
        axios.request(config)
                .then(function(response){
                    app.isLoading = false;
                    //console.log('error',response.data);
                    if(response.data.error){
                      app.errorMessage = response.data.message;                       
                    }
                    else if(response.data.text){                          
                        app.elementmark.classList.add('animate__zoomOut')
                        app.question = app.formDetails.ask
                        app.formDetails.ask = ''; 
                        app.totalResult=1;                                            
                        app.queryresult = marked.parse(response.data.text);
                        app.focusAsk();
                        app.clearMessage();
                        setTimeout(function(){                        
                            hljs.highlightAll();
                        }   , 1000);
                        
                       app.loadRelated(response.data.related)


                    }                    
                    else{
                      app.successMessage = response.data.message;
                      app.formDetails = {ask: ''};                      
                       
                    }
                })
                .catch(function (error) {
                  app.isLoading = false;
                  app.errorMessage = JSON.stringify(error);
                  console.log(error);
                  app.cleanvariables();
        });        

      },

      loadRelated: function(data){    
        this.related = []
        if (data.length > 1){ 
          this.related = data 
        }        
       
      },
      searchRelated: function(pageId){        
        const element = this.related.find( ({ id }) => id === pageId );
        if (element) {
          //this.cleanvariables();
           //console.log('searchRelated',element.title);
           this.formDetails.ask = element.title
           this.askAgility();
        }
       
      },
      focusAsk: function(){
            this.$refs.keyword.focus();
      }, 

      keymonitor: function(event){
        if(event.key == "Enter"){     
            
            this.askAgility();
        }
      },
      clearMessage: function(){
            this.errorMessage = '';
            this.successMessage = '';
            this.isLoading = false;
      },

      cleanvariables: function(){
        this.errorMessage   = '';
        this.successMessage = '';
        this.isLoading      = false;
        this.queryresult    = '';
        this.related        = [];

        this.elementmark.classList.remove('animate__zoomOut');
        this.elementmark.classList.add('animate__zoomIn')
            
      },

    }
}
</script>
